<!-- 首页 -->
<template>
  <div class="huayang"
    style="height: 100%;">
    <!-- 标题 -->
    <van-nav-bar title="中介超市"
      right-text="登录"
      fixed
      placeholder
      @click-right="onClickRight" />
    <!-- 侧边栏 -->
    <van-sidebar v-model="activeKey"
      style="
        width: 25%;
        float: left;
        position: fixed;
        overflow-y: scroll;
        height: 95%;"
    >
      <van-sidebar-item title="全部" @click="classifyClick(undefined)" />
      <van-sidebar-item v-for="item in classifyList" :key="item.index" :title="item.Name" @click="classifyClick(item.Coding)" />
    </van-sidebar>
    <!-- 内容 -->
    <div style="float: right; width: 75%;padding-bottom:80px" id="target">
      <van-list
  v-model="loading"
  :finished="finished"
  finished-text="没有更多了"
  @load="onLoad"
>
      <div class="list" v-for="item in list" :key="item.AId">
        <router-link :to="'/home/huayang/company/' + item.AId">
          <div
            style="
              display: inline-block;
              width: 90%;
              margin-left: 15px;"
          >
            <div style="font-size: 16px">{{item.Name}}</div>
            <div style="font-size: 14px; margin: 5px 0px">
              <span style="color:#188dfa;padding-right:5px" v-for="items in item.ClassifyName" :key="items">{{items}}</span>
            </div>
            <div style="font-size: 12px;" class="list_tabs van-multi-ellipsis--l3">
              <span v-html="item.Summary"></span>
            </div>
          </div>
        </router-link>
      </div>
      </van-list>
    </div>
    <!-- <div>
      <van-tabbar v-model="active">
        <van-tabbar-item icon="home-o"
          to="/huayang/classroom">知识大讲堂</van-tabbar-item>
        <van-tabbar-item icon="qr"
          to="/huayang/serve">资源看板</van-tabbar-item>
        <van-tabbar-item icon="apps-o"
          to="/huayang">中介超市</van-tabbar-item>
        <van-tabbar-item icon="cluster-o"
          to="/huayang/interaction">区企互动</van-tabbar-item>
        <van-tabbar-item icon="phone-circle-o"
          to="/huayang/lianluo">联系我们</van-tabbar-item>
      </van-tabbar>
    </div> -->
  </div>
</template>
<script>
import { WeGetAgencyPage } from "@/api/Hyapi";
import { GetCodeEntrysList } from "@/api/oldman";
import { getaccToken } from "@/utils/auth";
export default {
  data () {
    return {
      activeKey: 0,
      active: 2,
      classifyList:{}, //行业列表
      searchList:{ //中介机构查询参数
        page:1,
        limit:10,
        classify:undefined,
      },
      loading:false,
      finished:false,
      list:{  //中介机构列表
        
      },
    };
  },
  methods: {
    // 获取行业列表
    getCodeEntrysList(){
      GetCodeEntrysList({kind:256}).then((res)=>{
        this.classifyList = res.data.data;
      })
    },
    // 行业列表点击
    classifyClick(e){
      this.searchList.classify = e;
      target.scrollIntoView();
      this.getPush();
    },
    // 跳转登录
    onClickRight() {
      if(getaccToken() != undefined){
        this.$router.push({name:'firm'});
      }else{
        this.$router.push("/home/huayang/firmlogin");
      }
    },
    // 获取中介列表
    getPush(){
      WeGetAgencyPage(this.searchList).then((res)=>{
        this.list = res.data.data;
        this.listCount = res.data.count;
        for (let i = 0; i < this.list.length; i++) {
          var classArr = this.list[i].ClassifyName.split(",");
          this.list[i].ClassifyName = classArr
        }
        if(this.searchList.limit >= this.listCount) {
          this.finished = true;
        }
      })
    },
    onLoad(){
      this.searchList.limit += 10;
      this.getPush();
      this.loading = false;
    }
  },
  mounted(){
    this.getCodeEntrysList();
    // this.getPush();
  }
};
</script>
<style>
.huayang .van-sidebar-item--select {
  color: #188dfa;
  background: #fff;
}
.huayang .van-sidebar-item--select::before {
  background-color: #188dfa;
}
.huayang .list {
  width: 90%;
  padding: 7px;
  background: #f5f6f7;
  border-radius: 10px;
  margin: 8px 16px 15px 8px;
  box-shadow: 1px 1px 5px #ccc;
}
.huayang .list img {
  width: 70px;
  height: 70px;
}
.huayang .list div {
  color: #000;
}
.list_tabs span {
  color: #333;
}
.list_tabs p {
  margin-top: 0px;
}
.list_tabs p font{
  font-size: 12px;
}
.hidden {
  margin: 15px 0px;
  text-align: center;
  color: #666;
}
</style>